<template>
  <div class="news">
    <div class="img-box pr">
      <el-image class="wi100 hi100" :src="bg" fit="cover"></el-image>
      <div class="ban-dec uf uf-ver uf-pc">
        <div class="content-box">
          <div class="tit fwb tac">应用示范</div>
        </div>
      </div>
    </div>
    <div class="content-box pt-3 pb-2">
<!--      <div class="uf uf-pc">-->
<!--        <div class="box-tit f18 fwb"><span>核心示范企业</span></div>-->
<!--      </div>-->
<!--      <div class="company-box uf uf-pj">-->
<!--        <div class="c-list" v-for="(item, index) in company" :key="index">-->
<!--          <el-image :src="item.img" fit="cover" style="width: 100%;height: 300px;border-radius: 13px"></el-image>-->
<!--          <div class="fwb tac mt-3">{{ item.name }}</div>-->
<!--        </div>-->
<!--      </div>-->
      <div class="uf uf-pc mt-5">
        <div class="box-tit f18 fwb"><span>明星示范企业</span></div>
      </div>
      <div class="pro-box mb-4">
        <el-carousel :interval="4000" type="card" height="400px">
          <el-carousel-item v-for="(item, index) in company" :key="index">
            <div class="bg-box">
              <el-image :src="item.img" style="width: 100%;height: 100%" fit="cover"></el-image>
            </div>
            <div class="company uf uf-ver uf-ac uf-pc">
              <el-image :src="item.logo" fit="cover" style="width: 90px;height: 90px;border-radius: 50%"></el-image>
              <div class="fwb tac mt-3">{{ item.name }}</div>
            </div>
          </el-carousel-item>
        </el-carousel>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'news',
  data () {
    return {
      bg: require('../../assets/img/application.jpg'),
      company: [
        {name: '山东某某智能制造有限公司', img: 'http://pic5.58cdn.com.cn/p1/big/n_v1bl2lwwiww6tfqrfej47a.jpg', logo: require('@/assets/img/c-logo/01.jpg')},
        {name: '济南某某汽车有限公司', img: 'http://www.zhaoshang800.com/images/changfang/2020-05-11/202005110937559446.jpg', logo: require('@/assets/img/c-logo/02.jpg')},
        {name: '上海某某集团有限公司', img: 'https://pic.ntimg.cn/20140529/2531170_145055128000_2.jpg', logo: require('@/assets/img/c-logo/03.jpg')},
        {name: '广州某某互联网有限公司', img: 'https://gd-hbimg.huaban.com/ac8db0bcd87c5a86b1deccc77c8619f1a0cd7cd838611-5WAyzJ_fw658webp', logo: require('@/assets/img/c-logo/04.jpg')}
      ]
    }
  },
  methods: {
    handleSizeChange(val) {
    },
    handleCurrentChange(val) {
    }
  },
}
</script>
<!--transform: skewX(-30deg);-->
<style scoped lang="scss">
.pro-box {
  .bg-box {
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
  }
}
.company {
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  background: rgba(0,0,0,.1);
  backdrop-filter: blur(8px);
  color: white;
  font-size: 1.5vw;
  text-shadow: 0 0 20px rgba(0,0,0,.5);
}
.news {
  margin-top: -50px;
}
.img-box {
  width: 100vw;
  height: 26vw;
}
.tit {
  font-size: 4vw;
  color: white;
  padding-top: 2vw;
  letter-spacing: 30px;
  text-shadow: 0 0 20px rgba(0,0,0,.3);
}
.content-box {
  width: 1325px;
  margin: 0 auto;
}
.el-carousel__item h3 {
  color: #475669;
  font-size: 14px;
  opacity: 0.75;
  line-height: 200px;
  margin: 0;
}

.el-carousel__item:nth-child(2n) {
  background-color: #99a9bf;
}

.el-carousel__item:nth-child(2n+1) {
  background-color: #d3dce6;
}
.company-box {
  .c-list {
    padding: 20px;
    width: 23%;
    border-radius: 15px;
    background: #fff;
    box-shadow: 0 0 5px #ffb65f;
    border: 1px solid transparent;
    transition: all .3s ease;
    cursor: pointer;
    &:hover {
      border: 1px solid #00a6ff;
    }
  }
}
.n-list {
  padding: 30px 20px;
  background: #eeeeee;
  margin-bottom: 20px;
  border-radius: 10px;
  transition: all .3s ease;
  &:hover {
    box-shadow: 0 0 20px rgba(0,0,0,.2);
  }
}
.box-tit {
  width: 180px;
  line-height: 46px;
  color: white;
  background: #00a6ff;
  transform: skewX(-30deg);
  text-align: center;
  transition: all 0.3s ease;
  margin-bottom: 40px;
  margin-top: 20px;
  span {
    transform: skewX(30deg);
  }
}
.ban-dec {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;

  .dec-box {
    text-align: center;
    color: #ffffff;
    padding: 30px;
    .dec-tit {
      display: inline-block;
      background: transparent;
      font-size: 5vw;
      letter-spacing: 5px;
      margin-bottom: 1vw;
      text-shadow: 0 0 30px rgba(0,0,0,.4);
    }

    .dec-dec {
      line-height: 35px;
      opacity: .7;
    }
  }
}
</style>
